.color-pink {
  color: #ff1493 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .anchor {
    display: none;
  }
  &:hover {
    .anchor {
      display: inline;
    }
  }
}

// Smooth scrolling
html {
  scroll-behavior: smooth;
}

// Hide hero sub-heading on main page when in mobile
body.page-home .hero .large2 {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
}
// Make hero heading smaller on main page when in mobile
body.page-home .hero h1 {
  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
}

// Custom styling of drop down lists
details {
  margin-bottom: 10px;
  summary {
    font-size: 18px;
  }
}

// Hide Google reCAPTCHA badge
.grecaptcha-badge {
  visibility: hidden;
}

// Photo attribution
.photo-attribution {
  color: $text-muted;
}

// Cookie notice style definitions
#cookie-notice {
  display: none;
  padding: 1.5rem 1rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(16px);
  color: white;

  html[data-mode="dark"] & {
    background: rgba(var(--color-base-300), 0.6);
    color: white;
  }
}

#cookie-notice a {
  display: inline-block;
  cursor: pointer;
  margin-left: 0.5rem;
}

@media (max-width: 767px) {
  #cookie-notice span {
    display: block;
    padding-top: 3px;
    margin-bottom: 1rem;
  }

  #cookie-notice a {
    position: relative;
    bottom: 4px;
  }
}

