.footer {
  padding-top: $strip-padding * 2;
  padding-bottom: $strip-padding * 2;
  background-color: var(--color-base-300);
  .footer-heading {
    @extend .h5;

    color: var(--color-base-800);
    margin-bottom: 4px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        display: block;
        color: var(--color-base-600);
        text-decoration: none;
        padding: 2px 0 2px 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li.active {
      a {
        font-weight: bold;
      }
    }
  }
  .contact {
    > div {
      padding: 2px 0 2px 0;
      color: var(--color-base-600);
      line-height: 1.4;
      strong {
        color: var(--color-base-800);
        font-weight: normal;
      }
    }
  }
}
.sub-footer {
  background-color: var(--color-base-300);
  border-top: 1px solid var(--color-base-200);
  padding-top: $strip-padding;
  padding-bottom: $strip-padding;
}
.bottom {
  background-color: var(--color-base-400);
  padding-top: $strip-padding / 2;
  padding-bottom: $strip-padding / 2;
  .copyright {
    padding: 4px 0;
    @include media-breakpoint-up(md) {
      display: inline-block;
      padding: 0 0 0 8px;
    }
	opacity: 0.5;
  }
  .menu-bottom {
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include media-breakpoint-up(md) {
        margin: -8px;
      }
      li {
        margin: 0;
        padding: 0;
        display: block;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
        a {
          display: inline-block;
          color: var(--color-base-900);
          text-decoration: none;
          padding: 4px 0;
          @include media-breakpoint-up(md) {
            padding: 8px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
