.hero {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  background-color: var(--color-base-400);
  &.hero-gradient {
    background-image: linear-gradient(to right, $gradient-1, $gradient-2);
  }
  &.hero-fullscreen-desktop {
    @include media-breakpoint-up(md) {
      min-height: 100vh;
    }
  }
  &.hero-fullscreen-mobile {
    @include media-breakpoint-down(md) {
      min-height: 100vh;
    }
  }
  .hero-background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .hero-text {
    flex: 0;
    margin: auto 0;
    h1 {
      margin-bottom: 20px;
      color: inherit;
      font-weight: 300;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      strong {
        font-weight: 600;
      }
    }
    p {
      color: inherit;
    }
    &.hero-text-invert {
      color: var(--color-base);
      html[data-mode="dark"] & {
        color: var(--color-base-900);
      }
    }
  }
  .hero-flair {
    margin-left: 10px;
    font-weight: 500;
    font-family: $font-family-monospace;
    font-size: 20px;
    color: $primary;
  }
  .hero-image {
    img {
      max-width: 100%;
    }
  }
  .hero-buttons {
    margin-top: 20px;
    .btn {
      margin-top: 10px;
      @include media-breakpoint-down(sm) {
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
      }
    }
    .btn-outline-light:not(:hover) {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
