// Bootstrap Variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 740px,
  lg: 980px,
  xl: 1210px,
);

$grid-gutter-width: 30px;
$strip-padding: $grid-gutter-width;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    "gutter": $grid-gutter-width,
    "gutter-half": $grid-gutter-width / 2,
    0: 0,
    1: 10px,
    2: 20px,
    3: 30px,
    4: 40px,
    5: 50px,
    6: 60px,
    7: 70px,
    8: 80px,
    9: 90px,
    10: 100px,
    11: 110px,
    12: 120px,
    13: 130px,
    14: 140px,
    15: 150px,
    16: 160px,
    17: 170px,
    18: 180px,
    19: 190px,
    20: 200px,
  ),
  $spacers
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);

$box-shadow-sm: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
$box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);

// Theme Variables
$header-height: 60px;
$font-size-paragraph: 16px;
$font-size-paragraph-large: 18px;
