.strip {
  background-repeat: no-repeat;
  transition: background-color 0.3s;
  padding-top: $strip-padding * 2;
  padding-bottom: $strip-padding * 2;
  @include media-breakpoint-up(md) {
    padding-top: $strip-padding * 2;
    padding-bottom: $strip-padding * 2;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $strip-padding * 3;
    padding-bottom: $strip-padding * 3;
  }
  &:first-of-type {
    padding-top: ($strip-padding * 2) + ($header-height);
    padding-bottom: $strip-padding * 2;
    @include media-breakpoint-up(md) {
      padding-top: ($strip-padding * 2) + ($header-height);
      padding-bottom: $strip-padding * 2;
    }
    @include media-breakpoint-up(lg) {
      padding-top: $strip-padding * 3 + ($header-height);
      padding-bottom: $strip-padding * 3;
    }
  }
  .strip-heading {
    text-align: center;
    margin-bottom: $strip-padding;
  }
  .strip-footer {
    margin-top: $strip-padding;
    text-align: center;
  }
}
.strip-border {
  border-top: 1px solid var(--color-base-100);
  border-bottom: 1px solid var(--color-base-100);
}
.strip-border-top {
  border-top: 1px solid var(--color-base-100);
}
.strip-border-bottom {
  border-bottom: 1px solid var(--color-base-100);
}
.strip-base {
  background-color: var(--color-base);
  color: var(--color-base-900);
  
  p {
    color: var(--color-base-600);
  }
}
.strip-alt {
  background-color: var(--color-base-100);
  color: var(--color-base-900);
  border-color: var(--color-base-200);
  p {
    color: var(--color-base-600);
  }
}
.strip-primary {
  background-color: $primary;
  color: var(--color-base-900);
  p {
    color: var(--color-base-600);
  }
}
