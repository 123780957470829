.social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
  color: $primary;
  a {
    color: inherit;
    padding: 8px;
    display: block;
    &:hover {
      opacity: 0.8;
    }
    &:first-of-type {
      padding-left: 0;
    }
  }
}