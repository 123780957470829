.header {
  font-family: $font-family-heading;
  background-color: var(--color-base);
  padding: 0;
  transition: all 200ms linear;
  color: black;
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $header-height;
  }
  a {
    color: black;
  }
  .hamburger {
    color: black;
  }
}
.header-transparent {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  background-color: transparent !important;
  box-shadow: none !important;
  color: white;
  a {
    color: white;
  }
  .logos {
    .logo {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .logo-mobile {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .logo-invert {
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
    .logo-invert-mobile {
      display: block;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }
  .hamburger {
    color: white;
  }
}
.header-scrolled {
  background-color: rgb(255, 255, 255);
  transition: all 200ms linear;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: black;
  a {
    color: black;
  }
  .logos {
    .logo {
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
    .logo-mobile {
      display: block;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .logo-invert {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .logo-invert-mobile {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }
  .hamburger {
    color: black;
  }
}
.header-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

html[data-mode="dark"] {
  .header {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(16px);
    color: white;
    a {
      color: white;
    }
  }
  .header-scrolled {
    color: white;
    a {
      color: white;
    }
  }
  .logos {
    filter: invert(100%);
  }
  .header-transparent .logos {
    filter: invert(0%);
  }
  .hamburger {
    color: white;
  }
}
