/**
 * Syntax highlighting styles
 */
.highlight {
  background: #272822;

  pre {
    border: none;
    color: #fff;
    code {
      display: block;
      white-space: pre;
      overflow-x: auto;
      word-wrap: normal;
    }
  }

  .lineno {
    color: #ccc;
    display: inline-block;
    padding: 0 5px;
    border-right: 1px solid #ccc;
    user-select: none;
  }
  .c {
    color: #75715e;
    font-style: italic;
  } // Comment
  .err {
    color: #960050;
    background-color: #1e0010;
  } // Error
  .k {
    color: #66d9ef;
    font-weight: bold;
  } // Keyword
  .o {
    color: #f92672;
    font-weight: bold;
  } // Operator
  .l {
    color: #ae81ff;
  } // Literal
  .n {
    color: #f8f8f2;
  } // Name
  .p {
    color: #f8f8f2;
  } // Punctuation
  .cm {
    color: #75715e;
    font-style: italic;
  } // Comment.Multiline
  .cp {
    color: #75715e;
    font-weight: bold;
  } // Comment.Preproc
  .c1 {
    color: #75715e;
    font-style: italic;
  } // Comment.Single
  .cs {
    color: #75715e;
    font-weight: bold;
    font-style: italic;
  } // Comment.Special
  .gd {
    color: #f92672;
    background-color: #fdd;
  } // Generic.Deleted
  .gd .x {
    color: #000;
    background-color: #faa;
  } // Generic.Deleted.Specific
  .ge {
    font-style: italic;
  } // Generic.Emph
  .gr {
    color: #a00;
  } // Generic.Error
  .gh {
    color: #999;
  } // Generic.Heading
  .gi {
    color: #a6e22e;
    background-color: #dfd;
  } // Generic.Inserted
  .gi .x {
    color: #a6e22e;
    background-color: #afa;
  } // Generic.Inserted.Specific
  .go {
    color: #888;
  } // Generic.Output
  .gp {
    color: #555;
  } // Generic.Prompt
  .gs {
    font-weight: bold;
  } // Generic.Strong
  .gu {
    color: #75715e;
  } // Generic.Subheading
  .gt {
    color: #a00;
  } // Generic.Traceback
  .kc {
    color: #66d9ef;
    font-weight: bold;
  } // Keyword.Constant
  .kd {
    color: #66d9ef;
    font-weight: bold;
  } // Keyword.Declaration
  .kn {
    color: #f92672;
  } // Keyword.Namespace
  .kp {
    color: #66d9ef;
    font-weight: bold;
  } // Keyword.Pseudo
  .kr {
    color: #66d9ef;
    font-weight: bold;
  } // Keyword.Reserved
  .kt {
    color: #66d9ef;
    font-weight: bold;
  } // Keyword.Type
  .m {
    color: #ae81ff;
  } // Literal.Number
  .s {
    color: #e6db74;
  } // Literal.String
  .ld {
    color: #e6db74;
  } // Literal.Date
  .na {
    color: #a6e22e;
  } // Name.Attribute
  .nb {
    color: #f8f8f2;
  } // Name.Builtin
  .nc {
    color: #a6e22e;
    font-weight: bold;
  } // Name.Class
  .no {
    color: #66d9ef;
  } // Name.Constant
  .nd {
    color: #a6e22e;
  } // Name.Decorator
  .ni {
    color: #f8f8f2;
  } // Name.Entity
  .ne {
    color: #a6e22e;
  } // Name.Exception
  .nf {
    color: #a6e22e;
  } // Name.Function
  .nl {
    color: #f8f8f2;
  } // Name.Label
  .nn {
    color: #f8f8f2;
  } // Name.Namespace
  .nx {
    color: #a6e22e;
  } // Name.Other
  .py {
    color: #f8f8f2;
  } // Name.Property
  .nt {
    color: #f92672;
  } // Name.Tag
  .nv {
    color: #f8f8f2;
  } // Name.Variable
  .ow {
    color: #f92672;
    font-weight: bold;
  } // Operator.Word
  .w {
    color: #f8f8f2;
  } // Text.Whitespace
  .mf {
    color: #ae81ff;
  } // Literal.Number.Float
  .mh {
    color: #ae81ff;
  } // Literal.Number.Hex
  .mi {
    color: #ae81ff;
  } // Literal.Number.Integer
  .mo {
    color: #ae81ff;
  } // Literal.Number.Oct
  .sb {
    color: #e6db74;
  } // Literal.String.Backtick
  .sc {
    color: #e6db74;
  } // Literal.String.Char
  .sd {
    color: #e6db74;
  } // Literal.String.Doc
  .s2 {
    color: #e6db74;
  } // Literal.String.Double
  .se {
    color: #ae81ff;
  } // Literal.String.Escape
  .sh {
    color: #e6db74;
  } // Literal.String.Heredoc
  .si {
    color: #e6db74;
  } // Literal.String.Interpol
  .sx {
    color: #e6db74;
  } // Literal.String.Other
  .sr {
    color: #e6db74;
  } // Literal.String.Regex
  .s1 {
    color: #e6db74;
  } // Literal.String.Single
  .ss {
    color: #e6db74;
  } // Literal.String.Symbol
  .bp {
    color: #f8f8f2;
  } // Name.Builtin.Pseudo
  .vc {
    color: #f8f8f2;
  } // Name.Variable.Class
  .vg {
    color: #f8f8f2;
  } // Name.Variable.Global
  .vi {
    color: #f8f8f2;
  } // Name.Variable.Instance
  .il {
    color: #ae81ff;
  } // Literal.Number.Integer.Long
}
