.menu-main-mobile {
  position: fixed;
  background: $primary;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  display: none;
  justify-content: center;
  flex-direction: column;
  &.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;
    z-index: 10;
    display: flex;
    li {
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.25s;
      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.45s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.5s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.55s;
      }
      &:nth-of-type(6) {
        animation-delay: 0.6s;
      }
      &:nth-of-type(7) {
        animation-delay: 0.65s;
      }
      &:nth-of-type(8) {
        animation-delay: 0.7s;
      }
    }
  }
  ul {
    font-size: 32px;
    font-family: $font-family-heading;
    text-align: center;
    list-style: none;
    margin: 0;
    flex: 0;
    li {
      display: block;
      position: relative;
      opacity: 0;
      a {
        display: block;
        position: relative;
        color: white;
        text-decoration: none;
        overflow: hidden;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 4px;
          text-decoration-thickness: 2px;
        }
      }
      ul.sub-menu {
        font-size: 26px;
      }
    }
  }
  > ul {
    padding: 20px 0;
  }
}
.hamburger-trigger {
  height: 40px;
  display: flex;
  align-items: center;
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.hamburger {
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  font-size: 0;
  transition: all 0.25s ease-in-out;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: currentColor;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-in-out;
  }
  &.is-active {
    border-color: transparent;
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
.lock-scroll {
  overflow: hidden;
}
