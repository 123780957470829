.logos {
  margin-right: auto;
  a {
    display: block;
    opacity: 1;
    img {
      vertical-align: bottom;
    }
  }
  .logo {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  .logo-mobile {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  .logo-invert {
    display: none;
  }
  .logo-invert-mobile {
    display: none;
  }
}
.header-transparent .logos img {
  filter: drop-shadow(0 1px 1px #000000);
}
