/* stylelint-disable */
$primary: #2196f3;
$secondary: #1976D2;

$gradient-1: $primary;
$gradient-2: $secondary;

$base: #FFFFFF;
$base-100: #edf2f4;
$base-200: #e3e4e8;
$base-300: #D1D5DB;
$base-400: #9CA3AF;
$base-500: #6B7280;
$base-600: #4B5563;
$base-700: #272525;
$base-800: #201f1f;
$base-900: #121418;

$base-dark: #121418;
$base-dark-100: #1c1f25;
$base-dark-200: #1f232b;
$base-dark-300: #222833;
$base-dark-400: #4B5563;
$base-dark-500: #6B7280;
$base-dark-600: #9CA3AF;
$base-dark-700: #D1D5DB;
$base-dark-800: #E5E7EB;
$base-dark-900: #F4F4F5;

$font-family-monospace: Fira Mono, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: Fira Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
$font-family-heading: Montserrat, -apple-system, Roboto, "Helvetica Neue", Arial, "Noto Sans";
$font-family-serif:  -apple-system, serif;
$font-family-sans-serif: $font-family-base;

:root{  
  --color-primary: #{$primary};
  --color-secondary: #{$secondary};
  --color-gradient-1: #{$gradient-1};
  --color-gradient-2: #{$gradient-2};
  --color-base: #{$base};
  --color-base-100: #{$base-100};
  --color-base-200: #{$base-200};
  --color-base-300: #{$base-300};
  --color-base-400: #{$base-400};
  --color-base-500: #{$base-500};
  --color-base-600: #{$base-600};
  --color-base-700: #{$base-700};
  --color-base-800: #{$base-800};
  --color-base-900: #{$base-900};
}
  
html[data-mode='dark'] {
  --color-base: #{$base-dark};
  --color-base-100: #{$base-dark-100};
  --color-base-200: #{$base-dark-200};
  --color-base-300: #{$base-dark-300};
  --color-base-400: #{$base-dark-400};
  --color-base-500: #{$base-dark-500};
  --color-base-600: #{$base-dark-600};
  --color-base-700: #{$base-dark-700};
  --color-base-800: #{$base-dark-800};
  --color-base-900: #{$base-dark-900};
}

@import 'style';
@import 'custom';